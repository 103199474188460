import { ADD_STATIONS } from '@store/types';

const STATIONS_INITIAL_STATE = {
  stations: [
    {
      id: 87773002,
      station_name: 'Montpellier Saint-Roch (Montpellier)',
      city: 'Montpellier',
      postal_code: '34000',
      insee: '34172',
    },
  ],
};

export default function appReducer(state = STATIONS_INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_STATIONS:
      const stationArray = [...state.stations, ...payload.stations];

      const jsonObject = stationArray.map(JSON.stringify);
      const uniqueSet = new Set(jsonObject);
      const uniqueArray = Array.from(uniqueSet).map(JSON.parse);
      return { ...state, stations: uniqueArray };
    default:
      return state;
  }
}
