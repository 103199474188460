import { Title } from '@containers/Title';
import { Typography } from '@containers/Typography';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faChevronRight,
  faEnvelope,
  faTrash,
  faUserAstronaut,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
export const CardUser = ({
  user_id,
  username,
  first_name,
  last_name,
  email,
  address,
  creation,
  shouldShowButtons,
  delay,
  onClick,
  onClickDelete,
}) => {
  const handleOnClick = () => {
    onClick();
  };

  const handleClickDelete = () => {
    onClickDelete();
  };

  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    setShowButtons(shouldShowButtons);
  }, [shouldShowButtons]);

  return (
    <motion.object
      className=" shadow-lg min-w-full  rounded-xl p-5 pr-8 cursor-pointer hover:bg-gray-primary duration-200"
      initial={{ opacity: 0, y: 100 }}
      animate={{
        opacity: 1,
        y: 0,
        x: 0,
        transition: { delay: delay },
      }}
      onClick={() => handleOnClick()}
    >
      <div className="flex flex-col divide-y-4">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-col items-center h-full w-20">
            <FontAwesomeIcon
              icon={faUserAstronaut}
              size="3x"
              className={
                'text-gray-icons hover:text-secondary-default duration-200'
              }
            />
          </div>

          <div className="w-7/12">
            <div className="flex flex-col justify-start">
              <Title size="h3" color="primary-default" font="ubuntu">
                {first_name + ' ' + last_name}
              </Title>
              <Typography
                color="text-primary-default"
                size="text-base md:text-lg"
                font="comfortaa"
                className=""
              >
                @{username}
              </Typography>
            </div>
          </div>

          <div className="">
            <FontAwesomeIcon
              icon={faChevronRight}
              rotation={showButtons ? 90 : 0}
              className={
                'text-gray-icons hover:text-secondary-default duration-200'
              }
            />
          </div>
        </div>
        {showButtons && (
          <div className="w-full mt-4">
            <div className="flex flex-row justify-around w-1/3 lg:w-1/3 mt-4">
              <FontAwesomeIcon
                icon={faEdit}
                size="2x"
                className={
                  'text-gray-icons hover:text-secondary-default duration-200'
                }
              />

              <a href={'mailto:' + email} title={`Send a mail to ${username}`}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="2x"
                  className="text-gray-icons hover:text-secondary-default duration-200"
                />
              </a>

              <button
                title="Delete user from Cacahuete"
                onClick={() => handleClickDelete()}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="2x"
                  className="text-gray-icons hover:text-secondary-default duration-200"
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </motion.object>
  );
};
