import React from 'react';
import { cn } from '@utils/classes.util';

export const Title = ({
  children,
  size = 'h1', //H1, h2, h3
  color,
  font = 'ubuntu',
  bold = false,
  light = false,
  uppercase = false,
  className = '',
}) => {
  let fontSize;
  let sizedChildren;

  const getClassName = (size) =>
    cn(
      size,
      bold && 'font-bold',
      light && 'font-light',
      uppercase && 'uppercase',
      `text-${color}`,
      font && `font-${font}`,
      className,
    );

  switch (size) {
    case 'h1':
      fontSize = 'text-lg md:text-2xl lg:text-3xl';
      sizedChildren = <h1 className={getClassName(fontSize)}>{children}</h1>;
      break;
    case 'h2':
      fontSize = 'text-base md:text-lg lg:text-2xl';
      sizedChildren = <h2 className={getClassName(fontSize)}>{children}</h2>;
      break;
    case 'h3':
      fontSize = 'text-base md:text-lg';
      sizedChildren = <h3 className={getClassName(fontSize)}>{children}</h3>;
      break;
    case 'h4':
      fontSize = 'text-base';
      sizedChildren = <h4 className={getClassName(fontSize)}>{children}</h4>;
      break;
  }

  return <>{sizedChildren}</>;
};
