import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
export const Spinner = ({ loading }) => {
  return (
    <>
      {loading && (
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-secondary-default"
          size="6x"
          spin
        />
      )}
    </>
  );
};
