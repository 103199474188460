import Theme from '@components/Theme';
import { PrimaryButton } from '@containers/PrimaryButton';
import { Typography } from '@containers/Typography';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import {
  faCity,
  faCog,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKeyPress } from '@hooks/useKeyPress';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider } from '@mui/material/styles';
import { RandomIcon } from '@pages/RandomIcon';
import { deleteUser, getUser, updateUser } from '@store/actions/user.actions';
import { SHOW_MODAL } from '@store/types';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SecondaryButton } from './containers/SecondaryButton';

export const UpdateProfile = () => {
  const dispatch = useDispatch();

  const user = getUser();

  //const { execute, status, value, error } = useAsync(sendUser, false);

  const [values, setValues] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    username: user.username,
    address: user.address ?? '',
    postal_code: user.postal_code ?? '',
    city: user.city ?? '',
  });

  console.log(values);

  const [isLoading, setLoading] = useState(false);

  const sendUser = async () => {
    setLoading(true);
    let user = await updateUser(dispatch, values);
    setLoading(false);
    return user;
  };

  const isEnterPress = useKeyPress('Enter');

  useEffect(() => {
    if (isEnterPress && activateButton()) sendUser();
  }, [isEnterPress]);

  const activateButton = () => {
    return isPostalCodeValid();
  };

  const isPostalCodeValid = () => {
    return /^\d{5}$/.test(values.postal_code) || values.postal_code.length < 1;
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const widthInputs = { xs: '12rem', sm: '14rem', md: '16rem', lg: '18rem' };

  const handleClickDelete = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        modal: {
          title: 'Delete my account',
          description: 'Are you sure ? ',
          warningText: "⚠️ This action can't be undone",
          buttonText: 'Delete',
          buttonType: '',
          onClick: () => deleteUser(dispatch),
          open: true,
        },
      },
    });
  };

  return (
    <>
      <div className="flex flex-col items-center mt-10">
        <motion.div
          className="w-80 md:w-3/4  xl:w-8/12 rounded-lg shadow-lg flex flex-col items-center "
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.25 } }}
        >
          <RandomIcon size="6x" className="mt-8" color="primary-default" />
          <Typography
            color="text-gray-primary"
            font="comfortaa"
            className="mt-3"
          >
            @{values.username}
          </Typography>
          <Typography
            color="text-gray-primary"
            font="comfortaa"
            className="mb-3"
          >
            <FontAwesomeIcon size="2x" /> {values.email}
          </Typography>

          <ThemeProvider theme={Theme}>
            <div className="flex flex-col md:flex-row">
              <FormControl
                sx={{ m: 2, width: widthInputs }}
                variant="outlined"
                color="blue_secondary"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-first_name">
                  First name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-first_name"
                  value={values.first_name}
                  onChange={handleChange('first_name')}
                  startAdornment={
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={faAddressCard}
                        className="text-primary-default"
                      />
                    </InputAdornment>
                  }
                  label="First Name"
                />
              </FormControl>

              <FormControl
                sx={{ m: 2, width: widthInputs }}
                variant="outlined"
                color="blue_secondary"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-lastname">
                  Last name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-lastname"
                  value={values.last_name}
                  onChange={handleChange('last_name')}
                  label="Last name"
                />
              </FormControl>
            </div>

            <div className="flex flex-col lg:flex-row">
              <div className="flex flex-col md:flex-row">
                <FormControl
                  sx={{ m: 2, width: widthInputs }}
                  variant="outlined"
                  color="blue_secondary"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-address">
                    Address
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-address"
                    value={values.address}
                    onChange={handleChange('address')}
                    startAdornment={
                      <InputAdornment position="start">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="text-primary-default"
                        />
                      </InputAdornment>
                    }
                    label="Address"
                  />
                </FormControl>

                <FormControl
                  sx={{ m: 2, width: '12rem' }}
                  variant="outlined"
                  color={!isPostalCodeValid() ? 'secondary' : 'blue_secondary'}
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-postal-code">
                    Postal code
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-postal-code"
                    value={values.postal_code}
                    onChange={handleChange('postal_code')}
                    label="Postal code"
                    error={!isPostalCodeValid()}
                  />
                  <FormHelperText error>
                    {!isPostalCodeValid() ? 'Must be 5 numbers' : ''}
                  </FormHelperText>
                </FormControl>
              </div>
              <FormControl
                sx={{ m: 2, width: '12rem' }}
                variant="outlined"
                color="blue_secondary"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-postal-code">
                  City
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-city"
                  value={values.city}
                  onChange={handleChange('city')}
                  startAdornment={
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={faCity}
                        className="text-primary-default"
                      />
                    </InputAdornment>
                  }
                  label="City"
                />
              </FormControl>
            </div>
          </ThemeProvider>
          {isLoading && (
            <FontAwesomeIcon
              icon={faCog}
              className="text-primary-default"
              size="2x"
              spin
              className="m-4"
            />
          )}
          {!isLoading && (
            <PrimaryButton disabled={!activateButton()} action={sendUser}>
              Save
            </PrimaryButton>
          )}

          {/* <DangerZone show={t}/> */}
        </motion.div>
        <SecondaryButton
          action={() => handleClickDelete()}
          classOverrides="w-40 mt-10"
        >
          Delete Account
        </SecondaryButton>
      </div>
    </>
  );
};
