import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
  faUserAstronaut,
  faUserSecret,
  faUserNinja,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';
export const RandomIcon = ({ size, color = 'gray-icons', className }) => {
  const iconLists = [
    faUserCircle,
    faUserAstronaut,
    faUserSecret,
    faUserNinja,
    faUserGraduate,
  ];

  const [randomIcon, setRandomIcon] = useState(iconLists[0]);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * 5);
    setRandomIcon(iconLists[randomIndex]);
  }, []);

  return (
    <FontAwesomeIcon
      icon={randomIcon ?? faUserCircle}
      size={size}
      className={'text-' + color + ' ' + className}
    />
  );
};
