import React from 'react';
import { Typography } from '@containers/Typography';
export const Footer = () => {
  return (
    <footer className="flex p-5 flex-col md:flex-row justify-between bg-secondary-default text-white">
      <Typography classOverrides="ml-5">&copy; Cacahuète 2022</Typography>
      <Typography classOverrides="ml-5">Made with ❤ and React</Typography>
    </footer>
  );
};
