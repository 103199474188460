import { HeaderBar } from '@components/HeaderBar';
import Theme from '@components/Theme';
import { PrimaryButton } from '@containers/PrimaryButton';
import { faCog, faTrain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { addJourney } from '@store/actions/journey.actions';
import { AddStations } from '@store/actions/station.actions';
import { REDIRECT_HTTP } from '@store/types';
import { useKeyPress } from 'common/hooks/useKeyPress';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const SearchJourneyPage = () => {
  const [values, setValues] = React.useState({
    departure_date: new DateTime.now(),
    arrival_date: new DateTime.now(),
    departure_station: '',
    arrival_station: '',
    id_train: '',
  });

  const stations = useSelector((state) => state.stations.stations);

  const activateButton = () => {
    let areFieldComplete = true;
    if (
      !isTrainIdValid() ||
      !isStationValid(values.departure_station) ||
      !isStationValid(values.arrival_station) ||
      !areStationsDifferents() ||
      !areDateValid()
    )
      return false;
    Object.values(values).forEach((e, i) => {
      if (i < 6) {
        if (e.length < 1) {
          areFieldComplete = false;
        }
      }
    });
    return areFieldComplete;
  };

  const areDateValid = () => {
    return values.arrival_date.diff(values.departure_date).as('minutes') > 0;
  };

  const areStationsDifferents = () =>
    values.arrival_station.length < 1 ||
    values.arrival_station !== values.departure_station ||
    values.departure_station.length < 1;

  const isTrainIdValid = () => {
    return /^\d{1,6}$/.test(values.id_train) || values.id_train.length < 1;
  };

  const getStationByName = (stationName) => {
    return stations.filter((station) => station.station_name === stationName);
  };

  const isStationValid = (prop) => {
    return getStationByName(prop).length > 0 || prop.length < 1;
  };

  const { loading } = useSelector((state) => state.http);

  const sendJourney = async () => {
    const journey = {
      departure_station: getStationByName(values.departure_station)[0],
      arrival_station: getStationByName(values.arrival_station)[0],
      id_train: parseInt(values.id_train),
      departure_date: values.departure_date,
      arrival_date: values.arrival_date,
    };
    await addJourney(dispatch, journey, 'Journey succesfully added', true);
    dispatch({
      type: REDIRECT_HTTP,
      payload: { redirectTo: '/myjourneys' },
    });
  };

  const isEnterPress = useKeyPress('Enter');

  useEffect(() => {
    if (isEnterPress && activateButton()) sendJourney();
  }, [isEnterPress]);

  const dispatch = useDispatch();

  const handleChange = (prop) => async (event) => {
    let valueToChange;

    if (prop.endsWith('station')) {
      //Event.target.value came from the changes when typing in the input field
      //Event.target.textContent came from the changes when chnaging the field
      try {
        const station_name = event.target.value;

        if (event.target.value.length > 3) {
          await AddStations(dispatch, station_name);
        }
        valueToChange =
          event.target.value === 0
            ? event.target.textContent
            : event.target.value;
      } catch (e) {
        event.target.value = '';
      }
    } else valueToChange = event.target.value;

    if (prop && !prop.endsWith('date'))
      setValues({ ...values, [prop]: valueToChange });
  };

  const [times, setTimes] = useState({
    departure: null,
    arrival: null,
  });

  const parseDate = (date) => DateTime.fromISO(date.ts).toISO();

  const changeDepartureDate = (newDate) => {
    setValues({ ...values, departure_date: newDate });

    setTimes({ ...times, departure: parseDate(newDate) });
  };

  const changeArrivalDate = (newDate) => {
    setValues({ ...values, arrival_date: newDate });
    setTimes({ ...times, arrival: parseDate(newDate) });
  };

  return (
    <>
      <HeaderBar color="bg-white" textColor="primary-default">
        Add a journey
      </HeaderBar>

      <motion.div
        className="flex flex-col items-center mt-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.25 } }}
      >
        <div className="w-80 md:w-96 rounded-lg shadow-lg flex flex-col items-center ">
          <ThemeProvider theme={Theme}>
            <Autocomplete
              autoSelect
              autoComplete
              disablePortal
              freeSolo
              id="departure_station_selector"
              onChange={handleChange('departure_station')}
              options={stations.map((option) => option.station_name)}
              onInputChange={handleChange('departure_station')}
              sx={{ width: 300, m: 2 }}
              selectOnFocus
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    color="blue_secondary"
                    label="Departure station"
                  />
                  {!isStationValid(values.departure_station) ? (
                    <FormHelperText error>
                      Station name not found
                    </FormHelperText>
                  ) : null}
                </>
              )}
            />

            <Autocomplete
              autoSelect
              autoComplete
              disablePortal
              freeSolo
              id="arrival_station_selector"
              onChange={handleChange('arrival_station')}
              options={stations.map((option) => option.station_name)}
              onInputChange={handleChange('arrival_station')}
              sx={{ width: 300, m: 2 }}
              selectOnFocus
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    color="blue_secondary"
                    label="Arrival station"
                  />

                  <FormHelperText error>
                    {!isStationValid(values.arrival_station)
                      ? 'Station name not found'
                      : !areStationsDifferents()
                      ? 'Stations must be differents'
                      : null}
                  </FormHelperText>
                </>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimePicker
                renderInput={(props) => (
                  <TextField
                    {...props}
                    color="blue_secondary"
                    sx={{ m: 2, width: '12rem' }}
                  />
                )}
                label="Departure date"
                value={values.departure_date}
                onChange={changeDepartureDate}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimePicker
                renderInput={(props, value) => (
                  <>
                    <TextField
                      {...props}
                      value={value}
                      color={'blue_secondary'}
                      sx={{ m: 2, width: '12rem' }}
                    />
                    {!areDateValid() && (
                      <FormHelperText error sx={{ l: 0 }}>
                        Can't add date in the past
                      </FormHelperText>
                    )}
                  </>
                )}
                label="Arrival date"
                value={values.arrival_date}
                onChange={changeArrivalDate}
              />
            </LocalizationProvider>

            <TextField
              sx={{ m: 2, width: '12rem' }}
              id="outlined-adornment-id-train"
              value={values.id_train}
              onChange={handleChange('id_train')}
              label="Train id"
              color="blue_secondary"
              error={!isTrainIdValid()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faTrain}
                      className="text-primary-default"
                    />
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText error>
              {!isTrainIdValid() ? 'Must be between 1 and 6 numbers' : ''}
            </FormHelperText>

            {!loading && (
              <PrimaryButton disabled={!activateButton()} action={sendJourney}>
                Save
              </PrimaryButton>
            )}
            {loading && (
              <FontAwesomeIcon
                icon={faCog}
                className="text-primary-default m-4"
                size="2x"
                spin
              />
            )}
          </ThemeProvider>
        </div>
      </motion.div>
    </>
  );
};
