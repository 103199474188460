import React from 'react';
import { HeaderBar } from '@components/HeaderBar';

import { ButtonsProfile } from '@components/ButtonsProfile';
import { JourneysUser } from '@components/JourneysUser';
import { UpdateProfile } from '@components/UpdateProfile';
import { AdminSection } from '@components/AdminSection';
import { useAdmin } from 'common/hooks/useAdmin';

export const ProfilePage = (props) => {
  const isJourneyPage = () => path === 'myjourneys';
  const isProfilePage = () => path === 'profile';
  const isAdminPage = () => path === 'admin';

  const path = props.location.pathname.slice(1, props.location.pathname.length);

  const isAdmin = useAdmin();

  return (
    <>
      <HeaderBar>My account</HeaderBar>

      <ButtonsProfile path={path} isAdmin={isAdmin} />

      {isJourneyPage() && <JourneysUser />}
      {isProfilePage() && <UpdateProfile />}
      {isAdminPage() && <AdminSection />}
    </>
  );
};
