import { createStore, applyMiddleware, compose } from 'redux';
import reducers from '@store/reducers/';
import promiseMiddleware from 'redux-promise';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//Middleware wait before server response when getting data to pass to the component
const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(promiseMiddleware),
)(createStore);

const store = createStoreWithMiddleware(reducers);

export default store;
