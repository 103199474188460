import React from 'react';
import { cn } from '@utils/classes.util';

export const Typography = ({
  children,
  size,
  color = '',
  bold = false,
  italic = false,
  font = 'ubuntu',
  className = '',
}) => {
  return (
    <>
      <p
        className={cn(
          size,
          color,
          bold && 'font-bold',
          italic && 'italic',
          font && `font-${font}`,
          className,
        )}
      >
        {children}
      </p>
    </>
  );
};
