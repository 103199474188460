import React from 'react';
import { Title } from '@containers/Title';
import { Typography } from '@containers/Typography';
import { PrimaryButton } from '@containers/PrimaryButton';
import { getUser } from '@store/actions/user.actions';
import { REDIRECT_HTTP, SET_HTTP } from '@store/types';
import { useDispatch } from 'react-redux';
import { HTTP_STATUS } from '@constants/httpStatus.constants';
export const AddJourneyFooter = ({
  imgLeft,
  titleText,
  titleSize = 'h3',
  textButton = 'Add a journey',
  typoText = 'Add your own',
  typoColor = 'text-primary-default',
}) => {
  const dispatch = useDispatch();

  const handleClickAddJourney = () => {
    const user = getUser();
    if (!user) {
      dispatch({
        type: SET_HTTP,
        payload: {
          status: HTTP_STATUS.FAIL,
          message: 'Please login to add a journey',
          redirectTo: '/login',
        },
      });
    } else {
      dispatch({
        type: REDIRECT_HTTP,
        payload: { redirectTo: '/journey' },
      });
    }
  };

  return (
    <div className="flex items-center flex-col md:flex-row w-3/4 justify-around space-y-10">
      {imgLeft}
      <div className="flex flex-col items-start whitespace-nowrap">
        {titleText && (
          <Title size={titleSize} bold color="text-primary-default">
            {titleText}
          </Title>
        )}
        {typoText && (
          <Typography color={typoColor} className="mt-4">
            {typoText}
          </Typography>
        )}

        <PrimaryButton action={() => handleClickAddJourney()}>
          {textButton}
        </PrimaryButton>
      </div>
    </div>
  );
};
