import React from 'react';
import { Typography } from '@containers/Typography';
import { cn } from '@utils/classes.util';

export const Button = ({
  children,
  color,
  hover_color = 'bg-blue-secondary',
  action,
  disabled = false,
  classOverrides,
}) => {
  const onClickButton = () => {
    if (action) action();
  };

  const getColor = () => {
    return `hover:${hover_color} ${color}`;
  };

  return (
    <button
      onClick={onClickButton}
      className={cn(
        `${getColor()} disabled:bg-gray-disabled px-5 py-2 rounded-full mt-5 duration-300 enabled:hover:scale-105`,

        'mb-5',
        classOverrides,
      )}
      disabled={disabled}
    >
      <Typography bold size="text-base" color="text-white">
        {children}
      </Typography>
    </button>
  );
};
