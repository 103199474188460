import authHeader from '@auth/auth.header';
import { HTTP_STATUS } from '@constants/httpStatus.constants';
import { API_URL } from '@constants/routes';
import { isBeginPostalCode } from '@hooks/useValidator';
import {
  ADD_JOURNEY,
  ADD_JOURNEYS,
  DELETE_JOURNEY,
  GET_USER_JOURNEYS,
  LOADING_HTTP,
  LOADING_JOURNEYS,
  RELOAD_ALLJOURNEYS,
  SEARCH_JOURNEYS,
  SET_HTTP,
} from '@store/types';
import { buildRoute } from '@utils/routes.util';
import axios from 'axios';
import { getUser } from './user.actions';

export const getDepartureStation = (journey) =>
  journey?.departureStation ?? journey.departure_station;

export const getArrivalStation = (journey) =>
  journey?.arrivalStation ?? journey.arrival_station;

//Add journey to user
export const addJourney = async (
  dispatch,
  journey,
  message = 'Journey succesfully added',
  shouldReloadAll,
) => {
  dispatch({ type: LOADING_HTTP });
  try {
    const userId = getUser(dispatch).id;
    //Add a journey for user if already exists
    const route = journey.id
      ? `${API_URL}/users/${userId}/journeys/${journey.id}`
      : `${API_URL}/users/${userId}/journeys`;
    const response = await axios.post(route, journey, {
      headers: authHeader(),
    });

    if (response.status === 200) {
      dispatch({
        type: SET_HTTP,
        payload: {
          statusCode: 200,
          status: HTTP_STATUS.SUCCESS,
          message: message,
        },
      });
      dispatch({
        type: ADD_JOURNEY,
        payload: { journeys: [response.data.journey] },
      });
      if (shouldReloadAll) {
        dispatch({
          type: RELOAD_ALLJOURNEYS,
        });
      }
    }
    return response;
  } catch (error) {
    dispatch({
      type: SET_HTTP,
      payload: {
        statusCode: error.status,
        status: HTTP_STATUS.FAIL,
        message: 'User already registered for this journey',
      },
    });
    const response = error.response;
    return response;
  }
};
//Get users journeys
export const getUserJourneys = async (dispatch, userId) => {
  try {
    dispatch({ type: LOADING_JOURNEYS, payload: { loading: true } });
    const user_id = userId ?? getUser(dispatch).id;
    const response = await axios.get(`${API_URL}/users/${user_id}/journeys`, {
      headers: authHeader(),
    });
    const journeys = response.data.journeys;

    dispatch({
      type: GET_USER_JOURNEYS,
      payload: { journeys: journeys, count: journeys.length },
    });
  } catch (error) {
    dispatch({
      type: SET_HTTP,
      payload: { status: HTTP_STATUS.FAIL, message: error.message },
    });
    const response = error.response;

    return response;
  }
};

//Get all Journeys in the DB
export const addJourneys = async (dispatch, page, per_page) => {
  try {
    dispatch({ type: LOADING_JOURNEYS, payload: { loading: true } });
    const response = await axios.get(
      `${API_URL}/journeys?page=${page ?? 1}&per_page=${per_page ?? 5}`,
    );
    const journeys = response.data;

    dispatch({
      type: ADD_JOURNEYS,
      payload: {
        journeys: response.data.journeys,
        count: response.data.count,
        loadedJourney: (page - 1) * per_page + response.data.journeys.length,
        page: page,
      },
    });
  } catch (error) {
    dispatch({ type: LOADING_JOURNEYS, payload: { loading: false } });
    dispatch({
      type: SET_HTTP,
      payload: { status: HTTP_STATUS.FAIL, message: error.message },
    });
    const response = error.response;

    return response;
  }
};

export const deleteJourney = async (dispatch, journey, userId) => {
  console.log('JN' + journey.id);
  const user_id = userId ?? getUser(dispatch).id;
  try {
    const response = await axios.delete(
      `${API_URL}/users/${user_id}/journeys/${journey.id}`,
      {
        headers: authHeader(),
      },
    );
    dispatch({ type: DELETE_JOURNEY, payload: journey.id });
    dispatch({
      type: SET_HTTP,
      payload: {
        status: HTTP_STATUS.SUCCESS,
        message: 'Journey succesfully deleted',
      },
    });
    return response;
  } catch (error) {
    const response = error.response;
    dispatch({
      type: SET_HTTP,
      payload: { status: HTTP_STATUS.FAIL, message: error.message },
    });

    return response;
  }
};

const getCpOrCity = (value) => {
  let city, postal_code;
  if (isBeginPostalCode(value)) postal_code = value;
  else city = value;
  return { city: city, postal_code: postal_code };
};

export const searchByCityOrCP = async (dispatch, value) => {
  try {
    dispatch({ type: LOADING_JOURNEYS, payload: { loading: true } });
    let queryCity = '';
    let queryCP = '';
    const { city, postal_code } = getCpOrCity(value);

    if (city) queryCity = `city=${city}`;
    if (postal_code) queryCP = `postal_code=${postal_code}`;

    const query = buildRoute(`/journeys/departures`, queryCity, queryCP);

    let response = await axios.get(query);
    dispatch({
      type: SEARCH_JOURNEYS,
      payload: {
        searchJourneys: response.data.journeys,
        countSearch: response.data.count,
      },
    });
  } catch (error) {
    const response = error.response;
    dispatch({
      type: SET_HTTP,
      payload: { status: HTTP_STATUS.FAIL, message: error.message },
    });

    return response;
  }
};
