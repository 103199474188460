import { getUser } from '@store/actions/user.actions';
import { LOGIN } from '@store/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
export const useConnection = () => {
  const dispatch = useDispatch();

  const { user, isLoggedIn } = useSelector((state) => state.user);

  useEffect(() => {
    async function fetchUser() {
      if (!user) {
        const updatedUser = getUser(dispatch);

        if (updatedUser) {
          await dispatch({
            type: LOGIN,
            payload: { user: updatedUser },
          });
        }
      }
    }
    fetchUser();
  }, [user]);

  return isLoggedIn;
};
