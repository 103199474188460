import { Button } from '@containers/Button';
import React from 'react';

export const SecondaryButton = ({
  children,
  action,
  disabled,
  bold,
  classOverrides,
}) => {
  return (
    <Button
      color="bg-secondary-default"
      hover_color="bg-primary-default"
      action={action}
      disabled={disabled}
      bold={bold}
      classOverrides={classOverrides}
    >
      {children}
    </Button>
  );
};
