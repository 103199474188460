import Theme from '@components/Theme';
import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKeyPress } from '@hooks/useKeyPress';
import { FormControl } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider } from '@mui/material/styles';
import { searchByCityOrCP } from '@store/actions/journey.actions';
import { HIDE_SEARCH_JOURNEYS } from '@store/types';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PrimaryButton } from './PrimaryButton';
export const SearchBar = () => {
  const [value, setValue] = useState('');

  const [showLabel, setLabel] = useState(true);

  const pressEnter = useKeyPress('Enter');
  const pressEscape = useKeyPress('Escape');

  const validateField = () => value.length > 0;

  const dispatch = useDispatch();

  const searchJourney = () => {
    searchByCityOrCP(dispatch, value);
  };

  useEffect(() => {
    if (validateField() && pressEnter) searchJourney();
  }, [pressEnter]);

  useEffect(() => {
    if (pressEscape) dispatch({ type: HIDE_SEARCH_JOURNEYS });
  }, [pressEscape]);

  const handleChange = (prop) => (event) => {
    setValue(event.target.value);
  };

  const hideLabel = () => {
    setLabel(false);
  };

  return (
    <>
      <nav className="w-full flex flex-col items-center absolute -mt-12">
        <ThemeProvider theme={Theme}>
          <FormControl>
            {showLabel && (
              <InputLabel
                htmlFor="outlined-input-searchbar"
                sx={{
                  ml: { xs: 5, md: 6 },
                  pt: { xs: 8, md: 7.5 },
                  fontSize: { xs: '1rem', md: '1.5rem' },
                }}
              >
                Departure
              </InputLabel>
            )}
            <OutlinedInput
              //className={classes.searchbar}
              type={'search'}
              id="outlined-input-searchbar"
              onSelect={() => hideLabel()}
              value={value}
              onChange={handleChange()}
              sx={{
                m: 2,
                width: { xs: '14rem', sm: '22rem', md: '30rem', lg: '40rem' },
                border: 0,
                backgroundColor: 'white',
                boxShadow: '0px 0px 15px 5px rgba(0, 0, 0, 0.25)',
                height: 60,
                paddingLeft: '3',
                color: '#370617',
                fontFamily: 'Comfortaa',
                fontSize: { xs: '1rem', md: '1.5rem' },
              }}
              color="blue_secondary"
              startAdornment={
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faArrowRight} size="1x" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="start" sx={{ p: 0, m: 0, right: 0 }}>
                  {validateField() && (
                    <motion.div
                      className="p-0"
                      initial={{ opacity: 0, x: 10, scale: 0.75 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        scale: 1,
                        transition: { delay: 0.25 },
                      }}
                    >
                      <PrimaryButton
                        action={() => searchJourney()}
                        disabled={!validateField()}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </PrimaryButton>
                    </motion.div>
                  )}
                </InputAdornment>
              }
            ></OutlinedInput>
            <FormHelperText
              sx={{
                mt: -1,
                mb: 2,
                ml: 2,
                width: { xs: '14rem', sm: '22rem', md: '30rem', lg: '40rem' },
                fontSize: { xs: '.85rem', md: '1rem' },
              }}
            >
              City name or postal code
            </FormHelperText>
          </FormControl>
        </ThemeProvider>
      </nav>
    </>
  );
};
