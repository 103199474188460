import { HeaderBar } from '@components/HeaderBar';
import Theme from '@components/Theme';
import { PrimaryButton } from '@containers/PrimaryButton';
import { Typography } from '@containers/Typography';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import {
  faAt,
  faCity,
  faCog,
  faMapMarkerAlt,
  faMask,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider } from '@mui/material/styles';
import { register } from '@store/actions/user.actions';
import { useKeyPress } from 'common/hooks/useKeyPress';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import validator from 'validator';

export const SigninPage = () => {
  const [values, setValues] = React.useState({
    first_name: '',
    last_name: '',
    email: '',
    pseudo: '',
    password: '',
    confirm_password: '',
    address: '',
    postal_code: '',
    city: '',
    showPassword1: false,
    showPassword2: false,
    showSpinner: false,
  });

  const handleClickShowPassword = (isOne = true) => {
    if (isOne) {
      setValues({
        ...values,
        showPassword1: !values.showPassword1,
      });
    } else {
      setValues({
        ...values,
        showPassword2: !values.showPassword2,
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const dispatch = useDispatch();

  const isEnterPress = useKeyPress('Enter');

  const sendInfo = async () => {
    setValues({ ...values, showSpinner: true });
    const user = {
      dispatch,
      email: `${values.email}`,
      username: `${values.pseudo}`,
      password: `${values.password}`,
      first_name: `${values.first_name}`,
      last_name: `${values.last_name}`,
      address: `${values.address}`,
      postal_code: `${values.postal_code}`,
      city: `${values.city}`,
    };

    await register(user);
    setValues({ ...values, showSpinner: false });
  };

  const isPostalCodeValid = () => {
    return /^\d{5}$/.test(values.postal_code) || values.postal_code.length < 1;
  };

  const showErrorConfirmationPassword = () => {
    return (
      !arePasswordEqual() &&
      values.confirm_password.length > 0 &&
      values.password.length > 0
    );
  };

  const activateButton = () => {
    let areFieldComplete = true;
    if (
      values.showSpinner ||
      !arePasswordEqual() ||
      !isPostalCodeValid() ||
      !isEmailValid()
    )
      return false;
    Object.values(values).forEach((e, i) => {
      if (i < 6) {
        if (e.length < 1) {
          areFieldComplete = false;
        }
      }
    });
    return areFieldComplete;
  };

  const isEmailValid = () => {
    return validateFields(values.email, 'email');
  };

  const arePasswordEqual = () => {
    return validateFields(
      [values.password, values.confirm_password],
      'password',
    );
  };

  const validateFields = (prop, type) => {
    if (type === 'email') {
      return validator.isEmail(prop);
    } else if (type === 'password') {
      return prop[0] === prop[1];
    }
  };

  useEffect(() => {
    if (isEnterPress && activateButton()) sendInfo();
  }, [isEnterPress]);

  return (
    <>
      <HeaderBar color="bg-primary-default">Create an account</HeaderBar>
      <div
        className="flex flex-col items-center mt-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.25 } }}
      >
        <div className="w-80 md:w-96 rounded-lg shadow-lg flex flex-col items-center ">
          <ThemeProvider theme={Theme}>
            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-first_name">
                First name *
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-first_name"
                value={values.first_name}
                onChange={handleChange('first_name')}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faAddressCard}
                      className="text-primary-default"
                    />
                  </InputAdornment>
                }
                label="First Name "
              />
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-lastname">
                Last name *
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-lastname"
                value={values.last_name}
                onChange={handleChange('last_name')}
                label="Last name"
              />
            </FormControl>
            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-pseudo">
                Pseudo *
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-pseudo"
                value={values.pseudo}
                onChange={handleChange('pseudo')}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faMask}
                      className="text-primary-default"
                    />
                  </InputAdornment>
                }
                label="Pseudo"
              />
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-email">
                Email *
              </InputLabel>
              <OutlinedInput
                type={'email'}
                id="outlined-adornment-email"
                value={values.email}
                onChange={handleChange('email')}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faAt}
                      className="text-primary-default"
                    />
                  </InputAdornment>
                }
                label="Email"
              />
              <FormHelperText>
                {!isEmailValid() && values.email.length > 0
                  ? 'Email is not valid'
                  : ''}
              </FormHelperText>
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password *
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword1 ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(true)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword1 ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color={
                showErrorConfirmationPassword() ? 'secondary' : 'blue_secondary'
              }
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-confirm_password">
                Confirmation *
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm_password"
                type={values.showPassword2 ? 'text' : 'password'}
                value={values.confirm_password}
                onChange={handleChange('confirm_password')}
                error={showErrorConfirmationPassword()}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(false)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword2 ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirmation "
              />
              <FormHelperText error>
                {showErrorConfirmationPassword()
                  ? 'Password does not match'
                  : ''}
              </FormHelperText>
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-address">
                Address
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-address"
                value={values.address}
                onChange={handleChange('address')}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      className="text-primary-default"
                    />
                  </InputAdornment>
                }
                label="Address"
              />
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color={!isPostalCodeValid() ? 'secondary' : 'blue_secondary'}
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-postal-code">
                Postal code
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-postal-code"
                value={values.postal_code}
                onChange={handleChange('postal_code')}
                label="Postal code"
                error={!isPostalCodeValid()}
              />
              <FormHelperText error>
                {!isPostalCodeValid() ? 'Must be 5 numbers' : ''}
              </FormHelperText>
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-postal-code">
                City
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-city"
                value={values.city}
                onChange={handleChange('city')}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faCity}
                      className="text-primary-default"
                    />
                  </InputAdornment>
                }
                label="City"
              />
            </FormControl>
          </ThemeProvider>
          <Typography size="text-sm" italic color="text-gray-primary">
            * these fields are required
          </Typography>
          {!values.showSpinner && (
            <FontAwesomeIcon
              size="2x"
              spin
              icon={faCog}
              className={`${
                values.showSpinner ? 'visible' : 'hidden'
              } text-primary-default my-4`}
            />
          )}
          {!values.showSpinner && (
            <PrimaryButton disabled={!activateButton()} action={sendInfo}>
              Create account
            </PrimaryButton>
          )}

          <Link to="/login" className="text-center text-gray-primary">
            Already registered ? <br />
            👉 <i>Connect to your account</i>
          </Link>
        </div>
      </div>
    </>
  );
};
