import { SHOW_MODAL, HIDE_MODAL } from '@store/types';

const MODAL_INTIAL_STATE = {
  modal: {
    title: '',
    description: '',
    warningText: '',
    buttonText: '',
    buttonType: '',
    onClick: null,
    open: false,
  },
};

export default function appReducer(state = MODAL_INTIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_MODAL:
      return {
        ...state,
        modal: payload.modal,
      };
    case HIDE_MODAL:
      return { ...state, modal: { ...state.modal, open: false } };
    default:
      return state;
  }
}
