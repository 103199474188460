import { PrimaryButton } from '@containers/PrimaryButton';
import { Title } from '@containers/Title';
import { Typography } from '@containers/Typography';
import RailCross from '@images/rail_cross.png';
import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

export const ErrorPage = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-evenly overflox-x-auto w-full items-center lg:items-start mt-0 lg:mt-32">
        <motion.div
          className="flex flex-col items-center"
          initial={{ opacity: 0, y: 100, scale: 0 }}
          animate={{ opacity: 1, y: 0, scale: 1, transition: { delay: 0.25 } }}
        >
          <img src={RailCross} alt="Railcrossing together" className="w-9/12" />
        </motion.div>
        <motion.div
          className="flex flex-col items-start mt-12 lg:mt-0"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.25 } }}
        >
          <Title color="primary-default" font="ubuntu" bold>
            404
          </Title>
          <Title
            color="primary-default"
            font="comfortaa"
            size="h2"
            bold
            className="mt-4"
          >
            This page doesn't exists yet...
          </Title>
          <Typography
            color="text-gray-icons"
            font="comfortaa"
            className="w-60 mt-6"
          >
            Look how beautiful this landscape is, what are you waiting for ?
          </Typography>
          <Link to="/journey">
            <PrimaryButton>Add a travel</PrimaryButton>
          </Link>
        </motion.div>
      </div>
    </>
  );
};
