import React from 'react';
import { Title } from '@containers/Title';

export const HeaderBar = ({ children, color, textColor }) => {
  const getBgColor = () => {
    return color ?? 'bg-secondary-default';
  };

  return (
    <header className={getBgColor() + ' h-40'}>
      <Title
        bold
        color={textColor ?? 'white'}
        size="h1"
        font="ubuntu"
        className="pt-5 text-center"
      >
        {children}
      </Title>
    </header>
  );
};
