import React from 'react';

import { Link } from 'react-router-dom';

import { Button } from '@containers/Button';

export const ButtonsProfile = ({ path, isAdmin }) => {
  console.log(path);

  const getActivatedButton = (currentPath) => (
    <Link to={'/' + currentPath}>
      <Button
        color={
          path === currentPath ? 'bg-primary-default' : 'bg-gray-secondary'
        }
        hover_color={
          path === currentPath ? 'bg-secondary-default' : 'bg-primary-default'
        }
      >
        {currentPath === 'myjourneys'
          ? 'Journeys'
          : currentPath[0].toUpperCase() + currentPath.slice(1)}
      </Button>
    </Link>
  );

  return (
    <div className="w-full flex flex-row justify-center">
      <div className="flex flex-row justify-around w-full lg:w-1/2">
        {getActivatedButton('profile')}
        {isAdmin && getActivatedButton('admin')}
        {getActivatedButton('myjourneys')}
      </div>
    </div>
  );
};
