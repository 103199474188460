import { AUTHENTICATION } from '@constants/authentication.constants';
import { Spinner } from '@containers/Spinner';
import { Typography } from '@containers/Typography';
import { deleteUser, getUsers } from '@store/actions/user.actions';
import { SHOW_MODAL } from '@store/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardUser } from './CardUser';
import { LittleSpinner } from './containers/LittleSpinner';

export const AdminSection = () => {
  const { users, count, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (true) {
      getUsers(dispatch);
    }
  }, [count]);

  const [selectedCard, setSelected] = useState(null);
  const [filteredUsers, setUsers] = useState([]);

  const handleClick = (e) => {
    setSelected(e !== selectedCard ? e : null);
  };

  const handleClickDelete = (e) => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        modal: {
          title: 'Delete this user',
          description: 'Are you sure ? ',
          warningText: "⚠️ This action can't be undone",
          buttonText: 'Delete',
          buttonType: '',
          onClick: () => deleteUser(dispatch, e),
          open: true,
        },
      },
    });
  };

  useEffect(() => {
    if (users)
      setUsers(users.filter((user) => user.type === AUTHENTICATION.USER_TYPE));
  }, [users]);

  return (
    <>
      <div className="w-full flex flex-row justify-center">
        <div className="w-6/12 flex flex-row justify-end items-baseline">
          <Typography font="comfortaa" color="gray-primary">
            {isNaN(count) && <LittleSpinner />}
            {!isNaN(count) && count - 1 + ' users'}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full space-y-10 mt-14">
        <div className="flex flex-col space-y-10  items-center justify-center w-11/12 md:w-9/12 lg:w-8/12 xl:w-1/2">
          {filteredUsers &&
            filteredUsers.length > 0 &&
            filteredUsers.map((user, index) => (
              <CardUser
                key={user.id}
                delay={0.25 + (index + 1) * 0.2}
                username={user.username}
                first_name={user.first_name}
                last_name={user.last_name}
                email={user.email}
                shouldShowButtons={user.id === selectedCard}
                onClick={() => handleClick(user.id)}
                onClickDelete={() => handleClickDelete(user.id)}
                user_id={user.id}
              />
            ))}

          <Spinner loading={loading} />
        </div>
      </div>
    </>
  );
};
