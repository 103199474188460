import { combineReducers } from 'redux';
//import station from './station.reducers';
import user from './user.reducer';
import journeys from './journeys.reducer';
import stations from './stations.reducer';
import http from './http.reducer';
import modal from './modal.reducer';

const rootReducer = combineReducers({
  user,
  journeys,
  stations,
  http,
  modal,
});

export default rootReducer;
