import { Footer } from '@components/Footer';
import { NavBar } from '@components/NavBar';
import React from 'react';

export const Layout = ({ children }) => {
  return (
    <>
      {/* <ThemeProvider initialTheme={getInitialTheme()}> */}
      <div className="min-h-screen relative flex flex-col h-full">
        <NavBar />
        <div className="flex-auto pb-16">{children}</div>
        <Footer />
      </div>
      {/* </ThemeProvider> */}
    </>
  );
};
