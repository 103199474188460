import {
  ADD_JOURNEY,
  ADD_JOURNEYS,
  DELETE_JOURNEY,
  DELETE_MYJOURNEYS,
  GET_USER_JOURNEYS,
  HIDE_SEARCH_JOURNEYS,
  LOADING_JOURNEYS,
  RELOAD_ALLJOURNEYS,
  SEARCH_JOURNEYS,
} from '@store/types';
//journeys: []
const JOURNEYS_INITIAL_STATE = {
  journeys: [],
  allJourneys: [],
  searchJourneys: [],
  showSearch: false,
  countSearch: 0,
  loadedJourney: 0,
  count: 0,
  allLoaded: false,
  loading: false,
  page: 1,
};

export default function appReducer(state = JOURNEYS_INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case LOADING_JOURNEYS:
      return { ...state, loading: payload.loading };
    case GET_USER_JOURNEYS:
      return { ...state, journeys: payload.journeys, loading: false };
    //Used to load journeys
    case ADD_JOURNEYS:
      //state.allJourneys.push(payload.journeys);
      return {
        ...state,
        allJourneys: [...state.allJourneys, ...payload.journeys],
        loadedJourney: payload.loadedJourney,
        count: payload.count,
        allLoaded: payload.loadedJourney >= payload.count,
        loading: false,
        page: payload.page + 1,
      };

    case ADD_JOURNEY:
      return {
        ...state,
        journeys: [...state.journeys, ...payload.journeys],
      };
    case RELOAD_ALLJOURNEYS:
      return {
        ...state,
        count: 0,
        allJourneys: [],
        allLoaded: false,
        loadedJourney: 0,
        page: 1,
      };
    case DELETE_JOURNEY:
      return {
        ...state,
        journeys: state.journeys.filter((journey) => journey.id !== payload.id),
        count: state.count - 1,
        allJourneys: [],
        loadedJourney: 0,
      };
    case DELETE_MYJOURNEYS:
      return {
        ...state,
        journeys: [],
      };
    case SEARCH_JOURNEYS:
      return {
        ...state,
        loading: false,
        searchJourneys: payload.searchJourneys,
        countSearch: payload.countSearch,
        showSearch: true,
      };
    case HIDE_SEARCH_JOURNEYS:
      return { ...state, showSearch: false };
    default:
      return state;
  }
}
