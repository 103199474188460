import { AUTHENTICATION } from '@constants/authentication.constants';
import { getUser } from '@store/actions/user.actions';
import { useSelector, useDispatch } from 'react-redux';
import { useConnection } from './useConnection';

export const useAdmin = () => {
  const isLoggedIn = useConnection();
  const dispatch = useDispatch();

  const isAdmin = getUser(dispatch)?.type === AUTHENTICATION.ADMIN_TYPE;

  return isAdmin;
};
