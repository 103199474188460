import { Typography } from '@containers/Typography';
import { faBell, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConnection } from '@hooks/useConnection';
import logo from '@images/Cacahuete_logo.svg';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { logout } from '@store/actions/user.actions';
import { useAdmin } from 'common/hooks/useAdmin';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  const isLoggedIn = useConnection();
  const isAdmin = useAdmin();

  useEffect(() => {}, [isLoggedIn]);

  const notifs = 2;

  const dispatch = useDispatch();

  const handleLogout = async () => {
    handleClose();
    await logout(dispatch);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotifIcon = (classOverrides) =>
    notifs > 0 ? (
      <span className={classOverrides + ' flex flex-row items-center'}>
        <Typography
          color="text-white"
          bold
          size=""
          className="flex-shrink-0  bg-secondary-default py-1 px-3 rounded-full -mr-6 -mb-3 z-10 hover:cursor-pointer "
        >
          {notifs}
        </Typography>
        <FontAwesomeIcon
          icon={faBell}
          size="3x"
          color="#370617"
          className={' hover:cursor-pointer'}
        />
      </span>
    ) : null;
  return (
    <>
      <header className="h-12 md:h-max w-full flex flex-row items-center justify-center overflow-hidden">
        <div
          className=" flex flex-row items-center justify-between w-11/12"
          style={{ maxWidth: '2000px' }}
        >
          <Link className="w-auto hover:cursor-pointer flex flex-row" to="/">
            <img
              alt="Cacahuete Logo"
              src={logo}
              className="w-12 md:w-full mx-4"
            />
            <Typography
              size="text-4xl"
              bold
              color="primary-default"
              font="ubuntu"
              className="w-1 invisible md:visible md:w-auto font-comfortaa"
            >
              Cacahuète
            </Typography>
          </Link>

          <div className="flex flex-row items-center justify-center ">
            {isLoggedIn ? (
              <>
                {getNotifIcon('invisible lg:visible')}
                <IconButton
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  size="large"
                >
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="2x"
                    color="#370617"
                    className="text-2xl md:text-4xl text-primary-default hover:cursor-pointer hover:text-secondary-default transition duration-200"
                  />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  // elevation={0}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Grow}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {isAdmin && (
                    <Link to="/admin">
                      <MenuItem onClick={handleClose} divider>
                        <Typography bold color="text-blue-primary">
                          Admin Section
                        </Typography>
                      </MenuItem>{' '}
                    </Link>
                  )}
                  <Link to="/journeys">
                    <MenuItem onClick={handleClose}>All journeys</MenuItem>
                  </Link>
                  <Link to="/profile">
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                  </Link>
                  <Link to="/myjourneys">
                    <MenuItem onClick={handleClose} divider>
                      My journeys
                    </MenuItem>
                  </Link>
                  <MenuItem onClick={handleLogout}>
                    <Typography bold color="text-primary-default">
                      Logout
                    </Typography>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <Link to="/login" alt="Connect to the website">
                <Typography
                  color="primary-default"
                  bold
                  size="lg"
                  className="mx-4"
                >
                  Connect
                </Typography>
              </Link>
            )}
          </div>
        </div>
      </header>
    </>
  );
};
