import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const LittleSpinner = ({ loading }) => {
  return (
    <>
      {loading && (
        <FontAwesomeIcon
          icon={faCog}
          className="text-primary-default m-4"
          size="2x"
          spin
        />
      )}
    </>
  );
};
