import { toast } from 'react-toastify';

const DEFAULT_DURATION = 3000;
const DEFAULT_THEME = 'colored';

const setOptions = (options) => {
  let { theme, autoClose } = options;
  theme = theme ?? DEFAULT_THEME;
  autoClose = autoClose ?? DEFAULT_DURATION;
  return { ...options, theme, autoClose };
};

export const toastError = (content, options = {}) => {
  toast.error(content, setOptions(options));
};

export const toastSuccess = (content, options = {}) => {
  toast.success(content, setOptions(options));
};

export const toastWarning = (content, options = {}) => {
  toast.warning(content, setOptions(options));
};

export const toastInfo = (content, options = {}) => {
  toast.info(content, setOptions(options));
};
