import { AUTHENTICATION } from '@constants/authentication.constants';
import { HTTP_STATUS } from '@constants/httpStatus.constants';
import { SET_HTTP } from '@store/types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdmin } from 'common/hooks/useAdmin';
import { useConnection } from '@hooks/useConnection';
import { Redirect, Route } from 'react-router-dom';
/**
 * This component is used to protect a route, in this component
 * a route has a condition which leads the user into another route
 * @param {RouteConditions} conditions -- Condition to which the user needs to fill
 * @param {string} return_to -- The path where to the user is redirected
 * @param {JSX.Element} component -- Rendered component
 * @param rest
 * @constructor
 */
export const ProtectedRoute = ({
  condition,
  return_to,
  component,
  ...rest
}) => {
  const isLoggedIn = useConnection();
  const isAdmin = useAdmin();
  const dispatch = useDispatch();

  let conditionApproved = false;
  let messagePrevent = '';

  switch (condition) {
    case AUTHENTICATION.IS_CONNECTED:
      messagePrevent = 'You must be connected to perform this action';
      conditionApproved = isLoggedIn;
      break;
    case AUTHENTICATION.IS_ADMIN:
      conditionApproved = isAdmin; //user.user.type === AUTHENTICATION.ADMIN_TYPE;
      break;
    default:
      conditionApproved = false;
      break;
  }

  const redirectingComponent = (props) => {
    if (conditionApproved) {
      return React.createElement(component, props);
    } else {
      if (messagePrevent)
        dispatch({
          type: SET_HTTP,
          payload: {
            statusCode: 401,
            status: HTTP_STATUS.FAIL,
            message: messagePrevent,
          },
        });
      return <Redirect to={{ pathname: return_to }} />;
    }
  };

  return <Route {...rest} render={redirectingComponent} />;
};
