import { AddJourneyFooter } from '@components/AddJourneyFooter';
import { PrimaryButton } from '@components/containers/PrimaryButton';
import { SearchBar } from '@components/containers/SearchBar';
import { Spinner } from '@components/containers/Spinner';
import { Typography } from '@components/containers/Typography';
import { HeaderBar } from '@components/HeaderBar';
import { JourneysList } from '@components/JourneysList';
import { JOURNEYS_PER_PAGES } from '@constants/journeys.constants';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FenceEndLine from '@images/fence_not_found.png';
import { Title } from '@containers/Title';
import Skeleton from '@mui/material/Skeleton';
import { addJourneys } from '@store/actions/journey.actions';
import { HIDE_SEARCH_JOURNEYS } from '@store/types';
import { useOnScreen } from 'common/hooks/useOnScreen';
import { motion } from 'framer-motion';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const JourneysPage = () => {
  const dispatch = useDispatch();
  const ref = useRef();

  const {
    allJourneys,
    loadedJourney,
    allLoaded,
    count,
    loading,
    page,
    countSearch,
    searchJourneys,
    showSearch,
  } = useSelector((state) => state.journeys);

  const onScreen = useOnScreen(ref, '50px');

  //Activate whenever the user scroll down on the page
  useEffect(() => {
    if (loadedJourney > 0 && !loading && onScreen && !allLoaded && !showSearch)
      addJourneys(dispatch, page, JOURNEYS_PER_PAGES);
  }, [onScreen]);

  useEffect(() => {
    addJourneys(dispatch, page, JOURNEYS_PER_PAGES);
  }, []);

  const showJourneysList = () => {
    dispatch({ type: HIDE_SEARCH_JOURNEYS });
  };

  return (
    <>
      <HeaderBar>All journeys</HeaderBar>

      <SearchBar />
      <div className="w-full flex flex-row justify-center mt-10">
        <div className="w-6/12 flex flex-row justify-center mt-8 mb-2 md:mb-0 md:mt-0 md:justify-end items-baseline">
          <Typography font="comfortaa" color="gray-primary">
            {/* {(isNaN(count) || isNaN(countSearch) || !countSearch || !count || count === 0 || countSearch === 0 ) && <LittleSpinner />} */}
            {loading && <Skeleton animation="wave" width={85} height={35} />}
            {!loading && !showSearch && !isNaN(count) && count + ' journeys'}
            {!loading &&
              showSearch &&
              countSearch > 0 &&
              'Found ' + countSearch + ' matching journeys'}
          </Typography>
        </div>
      </div>

      {loadedJourney > 5 && !onScreen && (
        <motion.aside
          className="invisible xl:visible fixed flex flex-col justify-center items-center top-96 xl:w-max lg:right-0 xl:right-10 2xl:right-20 bg-white rounded-lg shadow-lg p-5"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.5 } }}
        >
          <AddJourneyFooter
            titleText="Not finding the right one ?"
            textButton="Add my journey"
          />
        </motion.aside>
      )}
      <div className="flex flex-col items-center justify-center w-full space-y-10 mt-10">
        {showSearch && (
          <PrimaryButton
            bold
            text="comfortaa"
            action={() => showJourneysList()}
            classOverrides="w-54"
          >
            <span className="flex flex-row items-center justify-center space-x-2">
              <FontAwesomeIcon
                icon={faArrowLeft}
                size="1x"
                className="text-color-white"
              />

              <span>See all journeys</span>
            </span>
          </PrimaryButton>
        )}
        <JourneysList
          allJourneys
          journeys={showSearch ? searchJourneys : allJourneys}
          indexOverrideDelay={
            showSearch ? 0 : loadedJourney - JOURNEYS_PER_PAGES
          }
        />

        <Spinner loading={loading} />

        <div
          ref={ref}
          className="flex flex-col w-full justify-center items-center"
        >
          {!showSearch && count === allJourneys.length && (
            <AddJourneyFooter
              titleText="Want's more ?"
              textButton="Add my journey"
            />
          )}
          {!loading && showSearch && countSearch < 1 && (
            <motion.div
              className="flex flex-col items-center justify-center "
              initial={{ opacity: 0, y: 100, scale: 0 }}
              animate={{
                opacity: 1,
                y: 0,
                scale: 1,
                transition: { delay: 0.25 },
              }}
            >
              <div>
                <Title
                  font="ubuntu"
                  size="h2"
                  color="primary-default"
                  className="mb-20 mt-4"
                  bold
                >
                  No associated journeys found
                </Title>
              </div>
              <div className="w-9/12 md:w-7/12 lg:w-5/12 mb-12">
                <img
                  src={FenceEndLine}
                  alt="A fence representing the end a of rail"
                />
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};
