import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { addJourney, addJourneys } from '@store/actions/journey.actions';

import { CardJourney } from '@components/CardJourney';
import {
  deleteJourney,
  getUserJourneys,
  getDepartureStation,
  getArrivalStation,
} from '@store/actions/journey.actions';
import { getUser } from '@store/actions/user.actions';
import { HTTP_STATUS } from '@constants/httpStatus.constants';
import { SET_HTTP } from '@store/types';
import { useConnection } from '@hooks/useConnection';
import { JOURNEYS_PER_PAGES } from '@constants/journeys.constants';

export const JourneysList = ({
  journeys,
  isHorizontal = true,
  indexOverrideDelay,
}) => {
  const [selectedCard, setSelected] = useState(null);

  const handleClick = (e) => {
    setSelected(e !== selectedCard ? e : null);
  };

  const journeysUser = useSelector((state) => state.journeys.journeys);

  const dispatch = useDispatch();

  const isConnected = useConnection();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (journeysUser.length < 1 && isConnected) {
      getUserJourneys(dispatch);
    }
  }, [isConnected]);

  const clickDelete = async () => {
    const response = await deleteJourney(dispatch, { id: selectedCard });
    await getUserJourneys(dispatch);
    await addJourneys(dispatch, 1, JOURNEYS_PER_PAGES);

    setSelected(null);
  };

  const clickRegister = async () => {
    const user = getUser();

    if (!user) {
      dispatch({
        type: SET_HTTP,
        payload: {
          status: HTTP_STATUS.FAIL,
          message: 'Please login to register into a journey',
          redirectTo: '/login',
        },
      });
    } else {
      const journey = journeys.find((journey) => journey.id === selectedCard);
      journey.userCount += 1;
      addJourney(
        dispatch,
        journey,
        'Successfully registered to journey !',
        false,
      );
    }
  };

  const showJourneyList = () => (
    <>
      {journeys.length > 0 &&
        journeys.map((journey, index) => (
          <div
            key={journey.id}
            className="flex flex-col items-center justify-center w-11/12 lg:w-10/12 xl:w-1/2"
          >
            <CardJourney
              key={journey.id}
              id={journey.id}
              id_train={journey.id_train}
              departure_station={getDepartureStation(journey).station_name}
              arrival_station={getArrivalStation(journey).station_name}
              departure_date={journey.departure_date}
              arrival_date={journey.arrival_date}
              nbUsers={journey.userCount}
              delay={0.5 + (index - indexOverrideDelay) * 0.2}
              onClick={() => handleClick(journey.id)}
              shouldShowButtons={journey.id === selectedCard}
              showRegisterButton={
                journeysUser.findIndex(
                  (journeyUser) => journeyUser.id === journey.id,
                ) === -1
              }
              showDeleteButton={
                journeysUser.findIndex(
                  (journeyUser) => journeyUser.id === journey.id,
                ) > -1
              }
              onClickDelete={() => clickDelete()}
              onClickRegister={() => clickRegister()}
            />
          </div>
        ))}
    </>
  );

  return <>{showJourneyList()}</>;
};
