export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CLEAR_LOGOUT = 'clear logout';
export const DELETE_USER = 'delete user';
export const GET_USER = 'get user';
export const GET_USERS = 'get users';
export const LOADING_USER = 'loading users';
export const UPDATE_USER = 'update user';
export const REGISTER = 'register';

export const ADD_STATIONS = 'add stations';
export const GET_JOURNEYS = 'get journeys';
export const GET_USER_JOURNEYS = 'get user journeys';
export const ADD_JOURNEY = 'add journey';
export const ADD_JOURNEYS = 'add journeys';
export const DELETE_JOURNEY = 'delete journeys';
export const DELETE_MYJOURNEYS = 'remove my journeys';
export const RELOAD_ALLJOURNEYS = 'reload all journeys';

export const LOADING_JOURNEYS = 'loading journeys';
export const SEARCH_JOURNEYS = 'search journeys';
export const HIDE_SEARCH_JOURNEYS = 'hide search journeys';

export const SET_HTTP = 'set http';
export const DELETE_HTTP = 'delete http';
export const LOADING_HTTP = 'loading http';
export const REDIRECT_HTTP = 'redirect http';

export const HIDE_MODAL = 'hide modal';
export const SHOW_MODAL = 'show modal';
