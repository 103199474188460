import { HTTP_STATUS } from '@constants/httpStatus.constants';
import { toastError, toastSuccess } from '@containers/Toasts';
import { UseModal } from '@hooks/useModal';
import { DELETE_HTTP, REDIRECT_HTTP } from '@store/types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { ToastContainer } from 'react-toastify';
export const HttpHandler = ({ children }) => {
  const { status, message, redirectTo } = useSelector((state) => state.http);
  const dispatch = useDispatch();
  useEffect(() => {
    if (status === HTTP_STATUS.SUCCESS) toastSuccess(message);
    else if (status === HTTP_STATUS.FAIL) toastError(message);

    dispatch({ type: DELETE_HTTP });
  }, [status]);

  useEffect(() => {
    if (redirectTo.length > 0)
      dispatch({ type: REDIRECT_HTTP, payload: { redirectTo: '' } });
  }, [redirectTo]);

  const { open } = useSelector((state) => state.modal.modal);

  return (
    <>
      <ToastContainer />
      <UseModal />
      {redirectTo.length > 0 && <Redirect to={redirectTo} />}
      {children}
    </>
  );
};
