import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      main: '#370617',
    },
    secondary: {
      main: '#900606',
    },
    blue_primary: {
      main: '#0096C7',
    },
    blue_secondary: {
      main: '#0077B6',
    },
  },
});

export default Theme;
