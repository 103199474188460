import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@containers/Typography';
import { Link } from 'react-router-dom';
import { getUserJourneys } from '@store/actions/journey.actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddJourneyFooter } from './AddJourneyFooter';
import { JourneysList } from './JourneysList';

export const JourneysUser = () => {
  const { journeys, loading } = useSelector((state) => state.journeys);

  const dispatch = useDispatch();

  useEffect(() => {
    if (journeys.length < 1) {
      getUserJourneys(dispatch);
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full space-y-10 mt-14">
      <JourneysList journeys={journeys} />
      {loading && (
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-secondary-default"
          size="6x"
          spin
        />
      )}

      <AddJourneyFooter
        titleText="Want's more journeys ?"
        textButton="Add a journey"
      />
      <Link to="/journeys" title="See all journeys">
        <Typography italic color="gray-primary">
          👉 See the full list
        </Typography>
      </Link>
    </div>
  );
};
