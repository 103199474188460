import React, { useState, useEffect } from 'react';
import { Title } from '@containers/Title';
import { Typography } from '@containers/Typography';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import User from '@images/user.svg';
import Clock from '@images/clock.svg';
import { motion } from 'framer-motion';
import TrainIcon from '@images/train_icon.png';
import CalendarIcon from '@images/calendar_icon.png';
import { SecondaryButton } from '@containers/SecondaryButton';
import { PrimaryButton } from '@containers/PrimaryButton';
export const CardJourney = ({
  id_train,
  departure_station,
  arrival_station,
  departure_date,
  arrival_date,
  nbUsers,
  delay,
  onClick,
  onClickDelete,
  onClickRegister,
  shouldShowButtons,
  showDeleteButton,
  showRegisterButton = false,
}) => {
  //const arrival_hour = arrival_date.toLocaleString(DateTime.TIME_SIMPLE)

  const departureDate = DateTime.fromISO(departure_date);
  const arrivalDate = DateTime.fromISO(arrival_date);

  const departure_hour = departureDate.toLocaleString(DateTime.TIME_SIMPLE);
  const arrival_hour = arrivalDate.toLocaleString(DateTime.TIME_SIMPLE);

  const departureTime = departureDate.toLocaleString(DateTime.DATE_SHORT);

  const journeyDuration = arrivalDate
    .diff(departureDate, ['hours', 'minutes'])
    .toFormat("h'h'mm");
  //console.log(`SBD${id}` + showButtonDelete);

  const [showButtons, setShowButtons] = useState(false);

  const [moveXposCard, setMoveXposCard] = useState(0); //Used to move the card on delete

  useEffect(() => {
    setShowButtons(shouldShowButtons);
  }, [shouldShowButtons]);

  const handleOnClick = () => {
    onClick();
  };

  const handleClickDelete = () => {
    setMoveXposCard(500);
    onClickDelete();
  };

  const handleClickRegister = () => {
    onClickRegister();
  };

  return (
    <motion.div
      className="shadow-lg w-11/12 lg:w-3/4 rounded-xl p-5 pr-8 cursor-pointer hover:bg-gray-primary duration-200"
      initial={{ opacity: 0, y: 100 }}
      animate={{
        opacity: moveXposCard > 0 ? 0 : 1,
        y: 0,
        x: moveXposCard,
        transition: { delay: delay },
      }}
      onClick={() => handleOnClick()}
    >
      <div className="flex flex-col divide-y-4">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-col justify-start w-full whitespace-nowrap">
            {/*Departure station and hour one line */}
            <div className="flex flex-row space-x-2 items-center h-8 w-full mb-0 lg:mb-2">
              <Typography
                color="text-primary-default"
                size="text-base md:text-lg"
                font="comfortaa"
                className="mr-0 md:mr-2"
              >
                {departure_hour}
              </Typography>
              <Title
                color="primary-default"
                font="ubuntu"
                size="h3"
                bold
                className="truncate w-7/12 lg:w-9/12 h-full"
              >
                {departure_station}
              </Title>
            </div>
            {/* Arrival station and hour */}
            <div className="flex flex-row space-x-2 items-center h-8 mb-8 lg:mb-10">
              <Typography
                color="text-primary-default"
                size="text-base md:text-lg"
                font="comfortaa"
                className="mr-0 md:mr-2"
              >
                {arrival_hour}
              </Typography>
              <Title
                color="primary-default"
                font="ubuntu"
                size="h3"
                bold
                className="truncate w-7/12 lg:w-9/12 h-full"
              >
                {arrival_station}
              </Title>
            </div>
            {/* Items at bottom, travellers count and hour*/}
            <div className="flex flex-row justify-around items-baseline">
              {/* Containing icons persons and time */}
              <div className="flex flex-row items-baseline w-1/2 space-x-6 ">
                <div className="flex flex-row items-baseline space-x-2">
                  <img
                    src={User}
                    className="w-5 md:w-8"
                    alt="Icon of the user"
                  />
                  <Typography
                    size="text-base md:text-lg"
                    color="text-gray-icons"
                  >
                    {nbUsers ?? 1}
                  </Typography>
                </div>
                <div className="flex flex-row items-baseline space-x-2">
                  <img
                    src={Clock}
                    className="w-5 md:w-8"
                    alt="Icon of a clock"
                  />
                  <Typography
                    size="text-base md:text-lg"
                    color="text-gray-icons"
                  >
                    {journeyDuration}
                  </Typography>
                </div>
              </div>
              <div className="w-1/3 text-left ">
                <div className="flex flex-row items-baseline space-x-2">
                  <img
                    src={CalendarIcon}
                    className="w-5 md:w-8"
                    alt="Calendar icon"
                  />
                  <Typography
                    size="text-base md:text-lg"
                    color="text-gray-icons"
                  >
                    {departureTime}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <FontAwesomeIcon
              icon={faChevronRight}
              rotation={showButtons ? 90 : 0}
              className={
                'text-gray-icons hover:text-secondary-default duration-200'
              }
            />
          </div>
        </div>
        {showButtons && (
          <div className="z-10 mt-12 -mb-2 flx md:flex-col flex-row ">
            <div className="flex flex-row  text-gray-icons items-center justify-center space-x-4">
              <img src={TrainIcon} className="-mb-8" alt="An icon of a Train" />
              <Typography size="text-base md:text-lg mt-8 ">
                {id_train}
              </Typography>
            </div>
            <div>
              {showDeleteButton && (
                <SecondaryButton
                  classOverrides=" w-52 "
                  action={() => handleClickDelete()}
                >
                  Delete Journey
                </SecondaryButton>
              )}
              {showRegisterButton && (
                <PrimaryButton
                  classOverrides="w-52 "
                  action={() => handleClickRegister()}
                >
                  Register
                </PrimaryButton>
              )}
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
};
