import {
  CLEAR_LOGOUT,
  DELETE_USER,
  GET_USERS,
  LOADING_USER,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
} from '@store/types';

const USER_INITIAL_STATE = {
  isLoggedIn: false,
  user: null,
  users: [],
  loading: false,
};

export default function appReducer(state = USER_INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
      return { ...state, isLoggedIn: true, user: payload.user, loading: false };
    case LOGOUT:
      return { ...state, isLoggedIn: false, user: null };
    case CLEAR_LOGOUT:
      return { ...state, wasLoggedIn: false };
    case UPDATE_USER:
      return { ...state, isLoggedIn: true, user: payload.user };
    case GET_USERS:
      return {
        ...state,
        users: payload.users,
        count: payload.count,
        loading: false,
      };
    case LOADING_USER:
      return { ...state, loading: payload.loading };
    case DELETE_USER:
      return {
        ...state,
        count: state.count - 1,
        users: state.users.filter((user) => user.id !== payload.id),
      };
    default:
      return state;
  }
}
