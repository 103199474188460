import React from 'react';
import TrainHP from '@images/train_homepage.png';
import TrainHPMobile from '@images/travel.png';
import TrainRdv from '@images/train_rdv.jpg';
import { Title } from '@containers/Title';
import { HomeCard } from '@components/HomeCard';
import {
  faMoon,
  faClock,
  faComment,
} from '@fortawesome/free-regular-svg-icons';

import { Link } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddJourneyFooter } from '@components/AddJourneyFooter';

export const HomePage = () => {
  return (
    <>
      <div className="flex flex-col items-center">
        <div className="flex lg:flex-col items-center flex-col-reverse">
          <div
            className="overflow-hidden flex flex-col items-center h-auto w-full "
            style={{ maxHeight: 700 }}
          >
            <img
              src={TrainHP}
              loading="lazy"
              className="lg:w-full lg:h-auto h-0 invisible md:visible"
              alt="a train on a bridge, with 2 people speaking inside"
            />
            <img
              src={TrainHPMobile}
              loading="lazy"
              className="w-full md:w-8/12 visible lg:hidden"
              alt="A train on the living, with a beautifull sunrising"
            />
          </div>
          <div className="text-center p-5 static lg:absolute xl:top-28 2xl:top-32 text-primary-default lg:text-white">
            <Title bold size="h1" className="mt-4">
              With Cacahuète, you will love your train travel
            </Title>
            <Title size="h2" bold className="m-4">
              Make delightful meetings and travel at the speed of light ⚡
            </Title>
          </div>
        </div>
        <div className="w-11/12 md:w-1/2 items-center my-12 lg:my-20 md:flex md:flex-row md:items-baseline justify-evenly">
          <HomeCard icon={faComment} title="Discover">
            Talk with exotic, new, charming, unique, awesome people
          </HomeCard>
          <HomeCard icon={faClock} title="Zen">
            Make the journey go even faster !
          </HomeCard>
          <HomeCard icon={faMoon} title="No stress">
            Travel peacefully without stress anymore
          </HomeCard>
        </div>
        <AddJourneyFooter
          imgLeft={
            <img
              src={TrainRdv}
              loading="lazy"
              className="w-72 md:w-1/2 lg:w-5/12"
              alt="A woman and a man meeing each other in the train"
            />
          }
          titleSize="h2"
          titleText="Where do you plan to go ?"
          typoText="Let's make this your most exciting trip ever"
          typoColor="text-gray-primary"
          textButton="Add a journey"
        />

        <div className="bg-primary-default w-full mt-12 text-white text-center h-52">
          <Link to="/journeys">
            <Title
              bold
              color={'white'}
              size="h3"
              font="ubuntu"
              className="mt-20 mb-4"
            >
              All journeys
            </Title>
            See the full list <FontAwesomeIcon icon={faArrowRight} size="1x" />
          </Link>
        </div>
      </div>
    </>
  );
};
