import { Button } from '@containers/Button';
import { SecondaryButton } from '@containers/SecondaryButton';
import { Title } from '@containers/Title';
import { Typography } from '@containers/Typography';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@mui/material/Modal';
import { HIDE_MODAL } from '@store/types';
import { motion } from 'framer-motion';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const UseModal = () => {
  const { modal } = useSelector((state) => state.modal);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: HIDE_MODAL });
  };

  return (
    <>
      <div>
        <Modal
          open={modal.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <motion.div
            className="px-5 pt-5 top-96 left-1/4 absolute w-1/2 bg-white rounded-lg shadow-lg "
            initial={{ opacity: 0, y: 0, scale: 0 }}
            animate={{ opacity: 1, y: 0, scale: 1, transition: { delay: 0.1 } }}
          >
            <div className="w-full flex flex-row justify-end">
              <FontAwesomeIcon
                icon={faTimes}
                className={'text-gray-icons right-5 cursor-pointer'}
                size="1x"
                onClick={handleClose}
              />
            </div>
            <div className="flex flex-col justify-center items-center">
              <Title font="ubuntu" color="primary-default" size="h2" bold>
                {modal.title}
              </Title>
              <Typography
                color="gray-primary"
                font="comfortaa"
                className="mt-10 mb-3"
              >
                {modal.description}
              </Typography>
              <Typography
                color="text-secondary-default"
                font="comfortaa"
                className="mb-5"
                bold
              >
                {modal.warningText}
              </Typography>
              <div className="w-full flex flex-row justify-center space-x-8">
                <SecondaryButton
                  action={() => {
                    modal.onClick();
                    handleClose();
                  }}
                >
                  {modal.buttonText}
                </SecondaryButton>
                <Button
                  color="bg-gray-icons"
                  hover_color="bg-gray-primary"
                  action={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </motion.div>
        </Modal>
      </div>
    </>
  );
};
