import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { HomePage } from '@pages/HomePage';
import { LoginPage } from '@pages/LoginPage';
import { SigninPage } from '@pages/SigninPage';
import { ProfilePage } from '@pages/ProfilePage';
import { SearchJourneyPage } from '@pages/SearchJourneyPage';
import { ErrorPage } from '@pages/ErrorPage';
import { AccessErrorPage } from '@pages/AccessErrorPage';
import { JourneysPage } from '@pages/JourneysPage';
import { Layout } from '@pages/LayoutPage';
import { HttpHandler } from '@components/HttpHandler';

import { ProtectedRoute } from '@components/ProtectedRoute';
import { AUTHENTICATION } from '@constants/authentication.constants';

export const IndexRouter = () => {
  return (
    <BrowserRouter>
      <HttpHandler>
        <Layout>
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/login" component={LoginPage} exact />
            <Route path="/signin" component={SigninPage} exact />
            <Route path="/journeys" component={JourneysPage} exact />
            <Route path="/journey" component={SearchJourneyPage} exact />
            <ProtectedRoute
              path="/profile"
              exact
              component={ProfilePage}
              return_to="/access_error"
              condition={AUTHENTICATION.IS_CONNECTED}
            />
            <ProtectedRoute
              path="/myjourneys"
              exact
              component={ProfilePage}
              return_to="/access_error"
              condition={AUTHENTICATION.IS_CONNECTED}
            />
            <ProtectedRoute
              path="/admin"
              exact
              component={ProfilePage}
              return_to="/access_error"
              condition={AUTHENTICATION.IS_ADMIN}
            />
            <Route path="/access_error" component={AccessErrorPage} />
            <Route path="/*" component={ErrorPage} />
          </Switch>
        </Layout>
      </HttpHandler>
    </BrowserRouter>
  );
};
