import { PrimaryButton } from '@containers/PrimaryButton';
import { Title } from '@containers/Title';
import { Typography } from '@containers/Typography';
import FenceEndLine from '@images/fence_not_found.png';
import { motion } from 'framer-motion';
import React from 'react';
import { Link } from 'react-router-dom';

export const AccessErrorPage = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-center items-center overflox-x-auto w-full mt-0 lg:mt-32">
        <motion.div
          className="flex flex-col items-center"
          initial={{ opacity: 0, y: 100, scale: 0 }}
          animate={{ opacity: 1, y: 0, scale: 1, transition: { delay: 0.25 } }}
        >
          <div className="w-8/12 xl:w-7/12">
            <img
              src={FenceEndLine}
              alt="A fence representing the end a of rail"
            />
          </div>
        </motion.div>
        <motion.div
          className="flex flex-col items-start mt-12 lg:mt-0"
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0, transition: { delay: 0.25 } }}
        >
          <Title color="primary-default" font="ubuntu" bold>
            404
          </Title>
          <Title
            color="primary-default"
            font="comfortaa"
            size="h2"
            bold
            className="mt-4"
          >
            You don't have the right to go here...
          </Title>
          <Typography
            color="text-gray-icons"
            font="comfortaa"
            className="w-60 mt-6"
          >
            Go back in safer place
          </Typography>
          <PrimaryButton>
            <Link to="/">Going Home</Link>
          </PrimaryButton>
        </motion.div>
      </div>
    </>
  );
};
