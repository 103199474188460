import React from 'react';
import { Title } from '@containers/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const HomeCard = ({ children, icon, title }) => {
  return (
    <div className="m-14">
      <FontAwesomeIcon
        icon={icon}
        size="6x"
        className="my-5 text-secondary-default"
      ></FontAwesomeIcon>
      <Title size="h3" color="primary-default" bold>
        {title}
      </Title>
      <div className="card-text-container max-w-sm mt-4">{children}</div>
    </div>
  );
};
