import { Button } from './Button';
import React from 'react';

export const PrimaryButton = ({
  children,
  action,
  disabled,
  bold,
  classOverrides,
}) => {
  return (
    <Button
      color="bg-blue-primary"
      hover_color="bg-blue-secondary"
      action={action}
      disabled={disabled}
      bold={bold}
      classOverrides={classOverrides}
    >
      {children}
    </Button>
  );
};
