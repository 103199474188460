import authHeader from '@auth/auth.header';
import { HTTP_STATUS } from '@constants/httpStatus.constants';
import { API_URL } from '@constants/routes';
import {
  DELETE_MYJOURNEYS,
  DELETE_USER,
  GET_USERS,
  LOADING_USER,
  LOGIN,
  LOGOUT,
  REDIRECT_HTTP,
  SET_HTTP,
  UPDATE_USER,
} from '@store/types';
import axios from 'axios';

export const login = async (dispatch, email, password) => {
  let response = null;
  try {
    dispatch({ type: LOADING_USER, payload: { loading: true } });
    response = await axios.post(`${API_URL}/users/login`, {
      email: email,
      password: password,
    });
    if (response.data?.user?.token) {
      localStorage.setItem('user', JSON.stringify(response.data));
      dispatch({
        type: LOGIN,
        payload: { user: response.data.user },
      });

      dispatch({
        type: SET_HTTP,
        payload: { status: HTTP_STATUS.SUCCESS, message: 'User connected' },
      });
      dispatch({
        type: REDIRECT_HTTP,
        payload: { redirectTo: '/myjourneys' },
      });
      return response.data;
    }
  } catch (error) {
    dispatch({
      type: SET_HTTP,
      payload: {
        status: HTTP_STATUS.FAIL,
        message: 'Invalid password or email',
      },
    });
    dispatch({
      type: LOADING_USER,
      payload: { loading: false },
    });
  }
};

export const getUser = () => {
  try {
    let response = JSON.parse(localStorage.getItem('user'));
    return response.user;
  } catch (error) {
    return false;
  }
};

export const updateUser = async (dispatch, user, userId) => {
  try {
    const userI = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      username: user.username,
      address: user.address,
      postal_code: user.postal_code ?? undefined,
      city: user.city,
    };

    const user_id = userId ?? getUser(dispatch).id;
    let response = await axios.post(
      `${API_URL}/users/${user_id}`,
      { user: userI },
      { headers: authHeader() },
    );
    dispatch({ type: UPDATE_USER, payload: { user: response.data.user } });
    dispatch({
      type: SET_HTTP,
      payload: {
        status: HTTP_STATUS.SUCCESS,
        message: 'User succesfully updated',
      },
    });
    localStorage.clear('user');
    localStorage.setItem('user', JSON.stringify(response.data));
    return response.data.user;
  } catch (error) {
    const response = error.response;
    dispatch({
      type: SET_HTTP,
      payload: { status: HTTP_STATUS.FAIL, message: error.message },
    });
    return response;
  }
};

export const logout = async (dispatch) => {
  localStorage.clear('user');
  await dispatch({
    type: REDIRECT_HTTP,
    payload: { redirectTo: '/' },
  });
  dispatch({ type: LOGOUT });
  await dispatch({
    type: SET_HTTP,
    payload: {
      status: HTTP_STATUS.SUCCESS,
      message: 'User succesfully disconnected',
    },
  });
  await dispatch({ type: DELETE_MYJOURNEYS }); //To avoid seeing others user journey when reconnecting back
};

export const register = async ({
  dispatch,
  email,
  username,
  password,
  first_name,
  last_name,
  address,
  postal_code,
  city,
}) => {
  let response = null;
  try {
    response = await axios.post(`${API_URL}/users`, {
      email: email,
      password: password,
      username: username,
      first_name: first_name,
      last_name: last_name,
      address: address.length > 0 ? address : null,
      postal_code: postal_code.length > 0 ? postal_code : null,
      city: city.length > 0 ? city : null,
    });
    dispatch({
      type: SET_HTTP,
      payload: {
        statusCode: 200,
        status: HTTP_STATUS.SUCCESS,
        message: 'User succesfully register',
      },
    });
    dispatch({
      type: REDIRECT_HTTP,
      payload: { redirectTo: '/login' },
    });
  } catch (error) {
    dispatch({
      type: SET_HTTP,
      payload: {
        statusCode: error.status,
        status: HTTP_STATUS.FAIL,
        message: 'Username or email already taken',
      },
    });
  }
  return response?.data ?? response;
};

export const deleteUser = async (dispatch, userId) => {
  const user_id = userId ?? getUser(dispatch).id;
  console.log(user_id);
  try {
    dispatch({ type: LOADING_USER, payload: { loading: true } });
    await axios.delete(`${API_URL}/users/${user_id}`, {
      headers: authHeader(),
    });
    dispatch({
      type: SET_HTTP,
      payload: {
        statusCode: 200,
        status: HTTP_STATUS.SUCCESS,
        message: 'User succesfully deleted',
      },
    });
    dispatch({
      type: DELETE_USER,
      payload: { id: user_id },
    });
    dispatch({ type: LOADING_USER, payload: { loading: false } });
    //Logout if user performed the action
    if (!userId) logout(dispatch);
  } catch (error) {
    dispatch({
      type: SET_HTTP,
      payload: {
        statusCode: error.status,
        status: HTTP_STATUS.FAIL,
        message: "You don't have the permissions to delete this user",
      },
    });
    dispatch({ type: LOADING_USER, payload: { loading: false } });
  }
};

export const getUsers = async (dispatch) => {
  try {
    dispatch({ type: LOADING_USER, payload: { loading: true } });
    const response = await axios.get(`${API_URL}/users`, {
      headers: authHeader(),
    });
    dispatch({
      type: GET_USERS,
      payload: { users: response.data.users, count: response.data.count },
    });
  } catch (error) {
    dispatch({
      type: SET_HTTP,
      payload: {
        statusCode: error.status,
        status: HTTP_STATUS.FAIL,
        message: error.message,
      },
    });
  }
};
