import axios from 'axios';
import { ADD_STATIONS } from '@store/types';
import { API_URL } from '@constants/routes';

export const AddStations = async (dispatch, stationName) => {
  console.log('ST' + stationName);
  try {
    const response = await axios.get(
      `${API_URL}/stations?station_name=${stationName}`,
    );
    if (response.data !== null) {
      const stations = response.data.stations;
      dispatch({ type: ADD_STATIONS, payload: { stations: stations } });
    }
  } catch (e) {}
  return null;
};
