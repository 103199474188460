import {
  SET_HTTP,
  DELETE_HTTP,
  LOADING_HTTP,
  REDIRECT_HTTP,
} from '@store/types';

const HTTP_INTIAL_STATE = {
  statusCode: null,
  message: null,
  status: null,
  response: {},
  redirectErrorPage: false,
  loading: false,
  redirectTo: '',
  shouldRedirect: false,
}; //Status = success, error or pending

export default function appReducer(state = HTTP_INTIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_HTTP:
      return { ...state, ...payload, loading: false };
    case LOADING_HTTP:
      return { ...state, loading: true };
    case DELETE_HTTP:
      return {
        ...state,
        statusCode: null,
        message: null,
        status: null,
        response: {},
        redirectErrorPage: false,
        loading: false,
        redirectTo: state.redirectTo,
      };
    case REDIRECT_HTTP:
      return { ...state, redirectTo: payload.redirectTo };
    default:
      return state;
  }
}
