import { HeaderBar } from '@components/HeaderBar';
import Theme from '@components/Theme';
import { LittleSpinner } from '@containers/LittleSpinner';
import { PrimaryButton } from '@containers/PrimaryButton';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider } from '@mui/material/styles';
import { login } from '@store/actions/user.actions';
import { useKeyPress } from 'common/hooks/useKeyPress';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import validator from 'validator';

export const LoginPage = () => {
  const loading = useSelector((state) => state.user.loading);

  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const isEmailValid = () => {
    return validateFields(values.email, 'email');
  };

  const validateFields = (prop, type) => {
    if (type === 'email') {
      return validator.isEmail(prop);
    }
  };

  const activateButton = () => {
    return isEmailValid() && values.password.length > 0;
  };

  const dispatch = useDispatch();

  const isEnterPress = useKeyPress('Enter');

  const sendInfo = async () => {
    await login(dispatch, values.email, values.password);
  };

  useEffect(() => {
    if (isEnterPress && activateButton()) sendInfo();
  }, [isEnterPress]);

  return (
    <>
      <HeaderBar>Connect to my account</HeaderBar>
      <motion.div
        className="flex flex-col items-center mt-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.25 } }}
      >
        <div className="w-80 md:w-96 rounded-lg shadow-lg flex flex-col items-center ">
          <ThemeProvider theme={Theme}>
            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              size="small"
              color="blue_secondary"
            >
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email"
                value={values.email}
                type={'email'}
                onChange={handleChange('email')}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon
                      icon={faAt}
                      className="text-primary-default"
                    />
                  </InputAdornment>
                }
                label="Error"
                //label={(!isEmailValid() && values.email.length > 0) ? 'Error' : 'Email'}
              />
              <FormHelperText>
                {!isEmailValid() && values.email.length > 0
                  ? 'Email is not valid'
                  : ''}
              </FormHelperText>
            </FormControl>

            <FormControl
              sx={{ m: 2, width: '12rem' }}
              variant="outlined"
              color="blue_secondary"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </ThemeProvider>
          {!loading && (
            <PrimaryButton disabled={!activateButton()} action={sendInfo}>
              Login
            </PrimaryButton>
          )}
          <LittleSpinner loading={loading} />
          <Link
            to="/signin"
            className="text-center hover:underline transition duration-500 mb-5"
            disabled
          >
            Not registered yet ? <br />
            👉 <i>Create an account</i>
          </Link>
        </div>
      </motion.div>
    </>
  );
};
